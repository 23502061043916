import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import media from 'utils/media-queries'
// import Breakpoints from '../utils/breakpoints'

const AppWrapper = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  overflow: auto;
  ${media.sm`
    overflow: hidden;
  `};
`

class App extends React.Component {
  render() {
    return (
      <AppWrapper>
        {/* <Breakpoints /> */}
        <Helmet>
          <html lang="en" />
          <title>Palettte App</title>
          <meta
            name="description"
            content="The definite palette editing and remapping tool"
          />
          <meta
            name="keywords"
            content="palette, color, generator, tool, colors"
          />
          <meta
            property="og:image"
            content="https://palettte.app/ogimage.jpg"
          />
          <meta
            property="og:description"
            content="The definitive palette editing and remapping tool"
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://palettte.app" />
          <meta property="og:title" content="Palettte App" />

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#004469" />
          <meta name="msapplication-TileColor" content="#004469" />
          <meta name="theme-color" content="#004469" />
        </Helmet>
        {this.props.children}
      </AppWrapper>
    )
  }
}

App.propTypes = {
  children: PropTypes.any,
}

export default App
